/* fileUploader.css */
.birthdayContainer{min-width: 1300px;}
.fileContainer{
  max-width: 1000px;
  margin-top: 250px;
}
.App {
    text-align: center;
    padding: 20px;
  }
  
  input[type='file'] {
    margin: 10px 0;
  }
  .uploaderr{
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    border: 2px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.13);
    margin: 10%;
    margin-left: 35%;
    width: 400px;
    height: 400px;
    

  }

  .body{
    /* background-color: #8c9ea7!important ; */
    
      background-image: url("./background-uploader.jpg"); /* Replace "../path/to/background.jpg" with the actual path to your image */
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      
  }
.uploadsheet{
  border: 1px solid white !important;
  border-radius: 7px !important;
}
  h1{
    text-align: center;
    margin-top: 10% !important;
  }
  .uploadsheetposition{
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    
}
.file-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}

.file-uploader-heading {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.file-input {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f44e;
  color: #333;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.file-input::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #e91111;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.file-input::file-selector-button:hover {
  background: #0d45a5;
}