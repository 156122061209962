
body {
    color: #000;
    overflow-x: hidden;
    height: 100%;
    background-repeat: no-repeat;
  }
  .signupContainer{
    height: 100vh;

  }
  .grayBackground{
    background-color: #8c9ea7!important ;
  
  
  }
  
  .card0 {
    /* box-shadow: 0px 4px 8px 0px #757575 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);

    border-radius: 0px;
  }
  
  .card2 {
    margin: 0px 40px;
  }
  
  .logo {
    width: 200px;
    height: 100px;
    margin-top: 20px;
    margin-left: 35px;
  }
  
  .image {
    width: 360px;
    height: 280px;
  }
  
  .border-line {
    border-right: 1px solid #EEEEEE;
  }
  
  .facebook {
    background-color: #3b5998;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .twitter {
    background-color: #1DA1F2;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .linkedin {
    background-color: #2867B2;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .line {
    height: 1px;
    width: 45%;
    background-color: #E0E0E0;
    margin-top: 10px;
  }
  
  .or {
    width: 10%;
    font-weight: bold;
  }
  
  .text-sm {
    font-size: 14px !important;
  }
  
  ::placeholder {
    color: #BDBDBD;
    opacity: 1;
    font-weight: 300
  }
  
  :-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 300
  }
  
  ::-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 300
  }
  
  input, textarea {
    padding: 10px 12px 10px 12px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  input:focus, textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #304FFE;
    outline-width: 0;
  }
  
  button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
  }
  
  a {
    color: inherit;
    cursor: pointer;
  }
  
  .btn-blue {
    background-color: #1A237E;
    width: 150px;
    color: #fff;
    border-radius: 2px;
  }
  
  .btn-blue:hover {
    background-color: #000;
    cursor: pointer;
  }
  
  .bg-blue {
    color: #fff;
    background-color: #1A237E;
  }
  
  @media screen and (max-width: 991px) {
    .logo {
        margin-left: 0px;
    }
  
    .image {
        width: 300px;
        height: 220px;
    }
  
    .border-line {
        border-right: none;
    }
  
    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px;
    }
  }