.container {
    display: flex;
    align-items: center;
    height: 80vh;
  }
  
  .logo {
    position: absolute;
    top: 1px;
    right: 3%;
    width: 95px;
    height: 125px;
    /* float:left; */
    padding-right: 0px;
  }

  .logoContainer {
    align-items: center;
    margin-left: 10px;
  }
  
  .milestone {
    position:absolute;
    width: 300px;
    height: 30px;
    margin-top: 10px;
    margin-left: 10px; 
    padding-right: 0px;
  }
  .logoImg {
    /* margin-right: 10px; Adjust the margin as needed */
    margin-left: 5px;
    margin-top: 65px;
  }

  .content{
    margin-left: 120px;
    /* width: 150px; */
  }
  
  .button {
    color: white;
    font-weight: bold;
    width: 400px;
    
    padding: 10px 20px;
    border-radius: 30px;  
    background-color: #0d6efd;
    margin-top: 20px;
    border-color: #0d6efd;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);

}
  
  .footer {
    width: 200%;
    height: 80px;
    background-color: #1A237E;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
    
  }
  
  .footer p {
    color: white;
    font-size: 22px;
    white-space: nowrap;
    margin: 0;
    padding: 24px;
    animation: marquee 40s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(52%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .by {
    font-weight: bold;
    color: #1A237E;
    text-align: center;
    
  }
  

  

  